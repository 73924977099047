import {ColorStyles} from "./Interfaces";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import {VECTOR_LAYERS} from "./../components/map/MapConstants";

export const generateMapFrictionStyles = function (): ColorStyles {
    const colorsScale = VECTOR_LAYERS.friction.colorScale;

    const redStyle = new Style({
        stroke: new Stroke({
            color: colorsScale(0.3).hex(),
            width: 3,
        }),
    });
    const yellowStyle = new Style({
        stroke: new Stroke({
            color: colorsScale(0.45).hex(),
            width: 3,
        }),
    });
    const greenStyle = new Style({
        stroke: new Stroke({
            color: colorsScale(1).hex(),
            width: 3,
        }),
    });

    return {redStyle, yellowStyle, greenStyle};
};

export const generateMapRoughnessStyles = function (): Record<number, Style> {
    const colorsScale = VECTOR_LAYERS.roughness.colorScale;

    let roughnessStyles : Record<number, Style> = {}

    for (let index = 0; index <= 12; index++) {
        const style = new Style({
            stroke: new Stroke({
                color: colorsScale(index).hex(),
                width: 3,
            })
        })
        roughnessStyles[index] = style;

        if (index < 3) {
            const extraIndex = index + 0.5;
            const extraStyle = new Style({
                stroke: new Stroke({
                    color: colorsScale(extraIndex).hex(),
                    width: 3,
                })
            })
            roughnessStyles[extraIndex] = extraStyle;
        }
    }

    return roughnessStyles;
};

