import * as React from "react";
import "./cookiebar.scss";

export interface CookieBarProps {}

export interface CookieBarState {
  showBar: boolean;
}

class CookieBar extends React.Component<CookieBarProps, CookieBarState> {
    constructor(props: CookieBarProps) {
        super(props);
        this.state = {
          showBar: (localStorage.acceptedCookies? false : true)
        };
    }

    componentDidMount() {}

    acceptCookies() {
        localStorage.acceptedCookies = true;
        this.setState({showBar: false});
        //console.log("accepted cookies", this.state.showBar)
    }

    render() {
        return (
            <div className={"cookiebar " + (!this.state.showBar? "hidden": "visible")}>
                <div>
                    <div>
                        <h6>Cookies</h6>
                        <div>
                            Yes, we use them for a better experinece
                            and to keep you signed in. Read more under <a href="/terms" className="link" target="_blank" rel="noopener noreferrer" >Terms & Conditions</a>.
                        </div>
                    </div>

                    <div className="actions">
                        <button className="button" onClick={() => this.acceptCookies()}> Accept & close</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CookieBar;
