import './RadioButton.scss'

interface Props {
    text: string
    id: string
    onClick: (id: string) => void;
    selected: boolean;
}

const RadioButton = (props: Props) => {
    const selected = props.selected ? "selected" : ""
    
    return (
        <div className="radioButtonContainer" tabIndex={0} onClick={() => props.onClick(props.id)}>
            <span className={"radioButtonTitle " + selected}>{props.text}</span>
            <div className={"radioButton " + selected}>
                <div className={"dot " + selected} />
            </div>
        </div>
    )
}

export default RadioButton
