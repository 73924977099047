import Amplify, {Auth} from "aws-amplify";
import {CognitoUserSession} from "amazon-cognito-identity-js";

import axios from "axios";
import {EventEmitter} from "events";

import {DevelopmentAPI, Configuration} from "./Configuration";

Amplify.configure({
    aws_project_region: Configuration.AWS_REGION,
    aws_cognito_identity_pool_id: Configuration.AWS_USER_POOL,
    aws_cognito_region: Configuration.AWS_REGION,
    aws_user_pools_id: Configuration.AWS_USER_POOL,
    aws_user_pools_web_client_id: Configuration.AWS_CLIENT_ID,
    oauth: {},
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: Configuration.AWS_USER_POOL, //?
        // REQUIRED - Amazon Cognito Region
        region: Configuration.AWS_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: Configuration.AWS_USER_POOL,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: Configuration.AWS_CLIENT_ID,
    },
});

export interface UserData {
    displayName?: string;
    email?: string;
    session?: CognitoUserSession;
    challenge?: {challengeName: string; challengeParam: any};
    user?: any;
    layers?: Array<String>;
    mapHome?: {lon: number; lat: number};
}
export interface UserMetaData {
    data: {
        layers?: Array<String>;
        mapHome?: {lon: number; lat: number};
    };
}

export interface ErrorMessage {
    type: string;
    error: any;
}
class LoginManager {
    eventEmitter: EventEmitter;
    session: CognitoUserSession | undefined;
    userData: UserData;

    constructor() {
        this.eventEmitter = new EventEmitter();
        this.eventEmitter.setMaxListeners(100);
        this.session = undefined;
        this.userData = {};
    }

    addDataListener(callback: (...args: any[]) => void) {
        this.eventEmitter.addListener("userData", callback);
    }

    removeDataListener(callback: (...args: any[]) => void) {
        this.eventEmitter.removeListener("userData", callback);
    }

    getSession() {
        return this.session;
    }

    async login(token: string) {
        return await axios({
            method: "post",
            url: `${DevelopmentAPI.BASE_URL}user/login-idp`,
            data: {
                token: token,
            },
            withCredentials: true,
            headers: {"Content-Type": "application/json"},
        });
    }

    async metaData(token: string) {
        return await axios({
            method: "get",
            url: `${DevelopmentAPI.BASE_URL}rsi-saas/metadata`,
            data: {
                token: token,
            },
            withCredentials: true,
            headers: {"Content-Type": "application/json"},
        });
    }

    async checkSignIn() {
        try {
            const res = await Auth.currentAuthenticatedUser();
            if (res.signInUserSession) {
                this.session = res.signInUserSession as CognitoUserSession;
                this.userData.email = res.attributes.email;
                this.userData.session = res.signInUserSession as CognitoUserSession;
                const loginRes = await this.getUserData();
                this.userData.displayName = loginRes.data.display_name;
                const metaData = await this.getMetaData();
                this.userData.mapHome = metaData.data.mapHome;
                this.userData.layers = metaData.data.layers;
                this.eventEmitter.emit("userData", this.userData);
            }
        } catch (error) {
            this.session = undefined;
            this.eventEmitter.emit("userData", undefined);
        }
    }

    async getMetaData(): Promise<UserMetaData> {
        try {
            if (this.session) {
                const metaData = await this.metaData(this.session.getIdToken().getJwtToken());
                return metaData;
            }
        } catch (error) {
            console.log("No metadata received", error);
        }
        return {data: {}};
    }

    async getUserData(): Promise<any> {
        if (!this.session) {
            return undefined;
        }
        const loginRes = await this.login(this.session.getIdToken().getJwtToken());

        return loginRes;
    }

    getCurrentUserData(): UserData {
        return this.userData;
    }

    async signOut() {
        try {
            await Auth.signOut({global: true});
            this.eventEmitter.emit("userData", undefined);
        } catch (error) {
            console.log("error signing out: ", error);
        }
    }

    async signIn(username?: string, password?: string): Promise<UserData | ErrorMessage | undefined> {
        try {
            if (!username) {
                return undefined;
            }

            var user = await Auth.signIn(username, password);

            if (this.shouldResetPassword(user?.challengeName)) {
                this.userData = {
                    user: user,
                    email: user?.attributes?.email,
                    challenge: {challengeName: user?.challengeName, challengeParam: user.challengeParam},
                };
                return this.userData;
            }
            const session = await Auth.userSession(user);
            const loginRes = await this.login(session.getIdToken().getJwtToken());
            this.userData = {session: session, displayName: loginRes?.data?.display_name, email: user?.attributes?.email};
            this.eventEmitter.emit("userData", this.userData);

            return this.userData;
            // You should now have a cookie named AUTH_SESSION
            // and crsf token, these will be used in following api requests...
        } catch (error) {
            console.log("error signing in", error);
            return {type: "error", error: error};
        }
    }

    public isErrorMessage(message: UserData | ErrorMessage): message is ErrorMessage {
        return (message as ErrorMessage).error !== undefined;
    }

    public async updatePasswordChallenge(newPassword: string) {
        // Needed for refresh tokens
        Auth.currentSession();
        try {
            const data = await Auth.completeNewPassword(
                this.userData.user, // the Cognito User Object
                newPassword // the new password
            );
            const session = await Auth.userSession(this.userData.user);
            const loginRes = await this.login(session.getIdToken().getJwtToken());
            this.userData = {session: session, displayName: loginRes?.data?.display_name, email: this.userData.user?.attributes?.email, user: this.userData};
            this.eventEmitter.emit("userData", this.userData);

            data.statusCode = 200;
            return data;
        } catch (error) {
            return error;
        }
    }

    public async forgotPasswordSubmit(userName: string, newPassword: string, verificationCode: string) {
        try {
            let data = {statusCode: 400};
            await Auth.forgotPasswordSubmit(userName, verificationCode, newPassword);
            data.statusCode = 200;
            return data;
        } catch (error) {
            return error;
        }
    }

    async sendForgotPasswordRequest(email: string) {
        try {
            const data = await Auth.forgotPassword(email);
            data.statusCode = 200;
            return data;
        } catch (error) {
            // console.log("Error when sending resetPasswordRequest", error);
            return error;
        }
    }

    public shouldResetPassword(challengeName: string) {
        if (challengeName === "NEW_PASSWORD_REQUIRED") {
            return true;
        }
        return false;
    }
}

const LoginManagerInstance = new LoginManager();
export default LoginManagerInstance;
