import {BaseResponse, ErrorResponse, CountryResponse, RegionResponse, PostMessageRequest, SignUpWriRequest, SignUpOemRequest, IdValuePair} from "./Interfaces";

import {DevelopmentAPI} from "./Configuration";

export async function getCountries(): Promise<IdValuePair[]> {
    const response: Response = await fetch(DevelopmentAPI.BASE_URL + "/rsi-saas/countries");
    var obj = {
        statusCode: response.status,
        ok: response.ok,
        statusText: response.statusText,
        raw: response,
    };

    var json: CountryResponse = await getJson(obj, response);

    const keys = Object.keys(json);
    let countries: IdValuePair[] = [];
    keys.forEach(key => {
        if (!isNaN(Number(key))) {
            countries.push(json[key]);
        }
    });
    return countries;
}

export interface RecipeInfo {
    id: number;
    updatedAt: string; // Timestamp
}
export async function getRegion(countryId: number) {
    const response: Response = await fetch(DevelopmentAPI.BASE_URL + "/rsi-saas/regions/" + countryId);
    var obj = {
        statusCode: response.status,
        ok: response.ok,
        statusText: response.statusText,
        raw: response,
    };

    var json: RegionResponse = await getJson(obj, response);

    const keys = Object.keys(json);
    let regions: IdValuePair[] = [];
    keys.forEach(key => {
        if (!isNaN(Number(key))) {
            regions.push(json[key]);
        }
    });
    return regions;
}

export async function postMessage(messageBody: PostMessageRequest): Promise<BaseResponse | ErrorResponse> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    const response: Response = await fetch(DevelopmentAPI.BASE_URL + "rsi-saas/contact-us", {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(messageBody),
    });
    var obj = {
        statusCode: response.status,
        ok: response.ok,
        statusText: response.statusText,
        raw: response,
    };
    return obj;
}
export async function signUpWri(messageBody: SignUpWriRequest): Promise<BaseResponse | ErrorResponse> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");

    const response: Response = await fetch(DevelopmentAPI.BASE_URL + "rsi-saas/wri/sign-up", {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(messageBody),
    });

    const errorText = await response.text();

    var obj = {
        statusCode: response.status,
        ok: response.ok,
        statusText: errorText || response.statusText,
        raw: response,
    };

    return obj;
}
export async function signUpOem(messageBody: SignUpOemRequest): Promise<BaseResponse | ErrorResponse> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    const response: Response = await fetch(DevelopmentAPI.BASE_URL + "rsi-saas/oem/sign-up", {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(messageBody),
    });

    const errorText = await response.text();

    var obj = {
        statusCode: response.status,
        ok: response.ok,
        statusText: errorText || response.statusText,
        raw: response,
    };

    return obj;
}

async function getJson(obj: BaseResponse, response: Response): Promise<BaseResponse> {
    if (isJson(response.headers)) {
        return {...obj, ...(await response.json())};
    } else {
        return obj;
    }
}

function isJson(headers: Headers): boolean {
    return headers.has("content-type") && headers.get("content-type")?.split(";")[0].toLowerCase() === "application/json";
}
