import * as React from "react";
import Map from "ol/Map";
import View from "ol/View";
import * as olSource from "ol/source";
import TileLayer from "ol/layer/Tile";
import {fromLonLat} from "ol/proj";
import {defaults} from "ol/interaction";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import Overlay from "ol/Overlay";
import {Attribution, defaults as defaultControls} from "ol/control";

import "./MapComponent.scss";
import VectorTileRenderType from "ol/layer/VectorTileRenderType";
import {MAP_TILE_LAYERS, NiraVectorLayer} from "./MapConstants";
import {generateMapFrictionStyles, generateMapRoughnessStyles} from "../../utils/MapUtils";
import {FeatureLike} from "ol/Feature";
import {ColorStyles} from "../../utils/Interfaces";
import Style from "ol/style/Style";

const REFRESH_INTERVAL = 60;

interface MapComponentProps {
    featureClicked: (feature: FeatureLike) => void;
    currentVectorLayerId: string;
    vectorLayers: Record<string, NiraVectorLayer>;
    coordinates?: {lon: number; lat: number};
    setShowError: (show: boolean) => void;
    mapHome?: {lon: number; lat: number};
}

export default class MapComponent extends React.Component<MapComponentProps> {
    map: Map;
    ref: any;
    markerRef: any;
    selected: any;
    vectorTileLayer: VectorTileLayer | undefined;
    selectedFeatures: any;
    selectionLayer: VectorTileLayer | undefined;
    vectorTileLayerSource: olSource.VectorTile;
    refreshDataTimer: NodeJS.Timeout;
    timerStartedAt: Date;
    frictionStyles: ColorStyles;
    roughnessStyles: Record<number, Style>;

    constructor(props: MapComponentProps) {
        super(props);
        this.ref = React.createRef();
        this.markerRef = React.createRef();
        this.selected = null;
        this.selectedFeatures = {};
        const zoom = 10;
        this.frictionStyles = generateMapFrictionStyles();
        this.roughnessStyles = generateMapRoughnessStyles();
        var attribution = new Attribution({
            collapsible: false,
            collapsed: true,
        });
        let center = [11.96649, 57.70488];
        if (props.mapHome) {
            center = [props.mapHome.lat, props.mapHome.lon];
        }

        let centerMerc = fromLonLat(center);
        let options = {
            view: new View({zoom: zoom, center: centerMerc, maxZoom: 22}),
            layers: [],
            overlays: [],
            renderer: "webgl",
            interactions: defaults({pinchRotate: false}),
            controls: defaultControls({attribution: true, zoom: false, rotate: false}).extend([attribution]),
        };

        this.map = new Map(options);
    }

    shouldComponentUpdate(props: MapComponentProps) {
        if (this.props.currentVectorLayerId !== props.currentVectorLayerId) {
            this.removeVectorTileLayer(this.vectorTileLayer);
            this.addVectorTileLayer(props.vectorLayers[props.currentVectorLayerId.toLowerCase()]);
        }
        if (this.props.coordinates !== props.coordinates) {
            const coords = fromLonLat([props.coordinates.lon, props.coordinates.lat]);
            this.map.getView().animate({
                center: coords,
                duration: 500,
            });
            this.addPositionMarker(coords);
        }

        if (this.props.mapHome !== props.mapHome) {
            this.gotToHomeRegion();
        }

        return false;
    }

    componentDidMount() {
        this.map.setTarget(this.ref.current);
        this.addTileLayer();
        this.addVectorTileLayer(this.props.vectorLayers.friction);
        this.addClickListener();
        this.addZoomListener();
        this.startRefreshDataTimer();
    }

    componentWillUnmount() {
        clearTimeout(this.refreshDataTimer);
    }

    gotToHomeRegion() {
        if (this.props.mapHome) {
            const coords = fromLonLat([this.props.mapHome.lat, this.props.mapHome.lon]);
            this.map.getView().animate({
                center: coords,
                duration: 500,
            });
        }
    }

    shouldRefreshData(startDate, currentDate, wishedIntervalTimeInSeconds): boolean {
        var timeDiff = (currentDate - startDate) / 1000; // in seconds;
        return timeDiff > wishedIntervalTimeInSeconds;
    }

    refreshVectorTileLayer() {
        const currentVectorTileLayer = this.props.vectorLayers[this.props.currentVectorLayerId.toLowerCase()];
        // if a specific timestamp is set, do not refresh
        if (currentVectorTileLayer.timestamp) {
            return;
        }
        let url = currentVectorTileLayer.url.toString();
        this.vectorTileLayerSource.setUrl(url);
        this.vectorTileLayerSource.clear(); // Make sure cache is cleared
        this.vectorTileLayerSource.refresh();
    }

    startRefreshDataTimer() {
        if (this.refreshDataTimer) {
            clearTimeout(this.refreshDataTimer);
        }

        // Check if we just had a refresh and we should start the reset the inteval timer
        if (!this.timerStartedAt) {
            this.timerStartedAt = new Date();
        }

        if (this.shouldRefreshData(this.timerStartedAt, new Date(), REFRESH_INTERVAL)) {
            this.refreshVectorTileLayer();
            this.timerStartedAt = null;
        }

        this.refreshDataTimer = setTimeout(() => {
            this.startRefreshDataTimer();
        }, 5000);
    }

    removeVectorTileLayer(layer?: VectorTileLayer) {
        if (!layer) {
            return;
        }
        this.map.removeLayer(layer);
    }

    getStyleFromValue(type: string, value) {
        switch (type) {
            case "Friction":
                if (value <= 0.35) {
                    return this.frictionStyles.redStyle;
                } else if (value <= 0.5) {
                    return this.frictionStyles.yellowStyle;
                }
                return this.frictionStyles.greenStyle;
            case "Roughness":
                const roundedNr = value < 3 ? Math.round(value / 0.5) * 0.5 : Math.round(value);
                if (Object.prototype.hasOwnProperty.call(this.roughnessStyles, roundedNr)) {
                    return this.roughnessStyles[roundedNr];
                }
                return this.frictionStyles.greenStyle;
            default:
                return this.frictionStyles.greenStyle;
        }
    }

    addVectorTileLayer(vectorLayer: NiraVectorLayer) {
        let url = vectorLayer.url.toString();

        if (vectorLayer.timestamp) {
            url = url + "?time=" + vectorLayer.timestamp;
        }

        this.vectorTileLayerSource = new VectorTileSource({
            format: new MVT(),
            maxZoom: 18,
            url: url,
        });
        this.vectorTileLayer = new VectorTileLayer({
            source: this.vectorTileLayerSource,
            renderMode: VectorTileRenderType.IMAGE,
            updateWhileInteracting: false,
            style: (feature, resolution) => {
                if (vectorLayer.id === "Roughness") {
                    if (feature.getProperties()["Long-term-Roughness"]) {
                        return this.getStyleFromValue(vectorLayer.id, Number(feature.getProperties()["Long-term-Roughness"]));
                    } else {
                        return undefined;
                    }
                }
                return this.getStyleFromValue(vectorLayer.id, Number(feature.getProperties()["Friction-Average"]));
            },
        });
        this.map.addLayer(this.vectorTileLayer);
    }

    addPositionMarker(coords) {
        const marker = new Overlay({
            position: coords,
            offset: [0, 0],
            element: this.markerRef.current,
            stopEvent: false,
        });

        this.map.addOverlay(marker);
    }

    addClickListener() {
        this.map.on("singleclick", event => {
            if (!this.vectorTileLayer) {
                return;
            }

            const features = this.map.getFeaturesAtPixel(event.pixel, {hitTolerance: 3});
            if (features.length > 0) {
                this.props.featureClicked(features[0]);
            }
        });
    }

    addZoomListener() {
        this.map.on("moveend", e => {
            const zoom = this.map.getView().getZoom();
            if (zoom < 10) {
                this.props.setShowError(true);
            } else {
                this.props.setShowError(false);
            }
        });
    }

    addTileLayer() {
        const niraSource = new olSource.XYZ({
            url: MAP_TILE_LAYERS.v2.url + "?key=" + MAP_TILE_LAYERS.v2.key,
            attributions: '<a href="https://maptiler.com/copyright">© MapTiler</a>',
        });

        const zIndex = 0;

        let tileLayer = new TileLayer({
            source: niraSource,
            zIndex,
        });

        this.map.addLayer(tileLayer);
        tileLayer.setZIndex(zIndex);
    }

    render() {
        return (
            <>
                <div ref={this.ref} className="mapComponentRoot" id="map" />
                <div ref={this.markerRef} id="marker" className="mapMarker">
                    <span className="dot"></span>
                </div>
            </>
        );
    }
}
