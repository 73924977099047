import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import {Router} from "react-router";
import {createBrowserHistory} from "history";
import ReactGoogleAnalytics from "react-ga";

import "./App.scss";
import CookieBarComponent from "./components/misc/CookieBar";
import MapsPage from "./pages/maps/Maps";
import LoginPage from "./pages/Login/Login";
import LoginManagerInstance, {UserData} from "./utils/LoginManager";
import {DevelopmentAPI} from "./utils/Configuration";

export interface AppProps {}
export interface AppState {
    authenticated: boolean;
}

const history = createBrowserHistory();
const mapUrl = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/";
const loginUrl = `${process.env.PUBLIC_URL}/login`;

class App extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {authenticated: false};
        this.loginPressed = this.loginPressed.bind(this);
        this.loginChanged = this.loginChanged.bind(this);
    }

    async componentDidMount() {
        // Needed for Google analytics to work
        history.listen((location, action) => {
            this.analytics(action);
        });
        // For the first run when the app is loaded.
        this.analytics("LOADED");
        LoginManagerInstance.addDataListener(this.loginChanged);
        await LoginManagerInstance.checkSignIn();
    }

    componentWillUnmount() {
        LoginManagerInstance.removeDataListener(this.loginChanged);
    }

    GoogleAnalyticsID = DevelopmentAPI.isProduction
        ? "UA-190644867-2" //Production
        : "UA-190644867-1"; //Test
    analytics(action: string) {
        ReactGoogleAnalytics.initialize(this.GoogleAnalyticsID);
        ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
        console.log(`The current URL is ${window.location.pathname}${window.location.search}${window.location.hash} by ${action}`);
    }

    loginChanged(userData: UserData) {
        const userSession = userData?.session;

        if (!userSession) {
            if (window.location.pathname !== loginUrl) {
                window.location.pathname = loginUrl;
            }
            return;
        }
        if (this.state.authenticated !== userSession.isValid()) {
            if (!userSession.isValid()) {
                if (window.location.pathname !== loginUrl) {
                    window.location.pathname = loginUrl;
                }
            } else {
                if (window.location.pathname !== mapUrl) {
                    window.location.pathname = mapUrl;
                }
            }
        }
        this.setState({authenticated: userSession.isValid()});
    }

    loginPressed() {
        LoginManagerInstance.signIn();
    }

    render() {
        const userData = LoginManagerInstance.getCurrentUserData();
        // Before userdata is set, we should not show any page.
        if ((!userData.session || !userData.session.isValid()) && window.location.pathname !== loginUrl) {
            return <div className="page-content"></div>;
        }

        return (
            <Router history={history}>
                <Switch>
                    <Route
                        path={mapUrl}
                        component={() => {
                            return (
                                <>
                                    <Switch>
                                        <Route path={mapUrl} component={MapsPage} exact />
                                        <Route path={[`${loginUrl}/:page`, `${loginUrl}/:page/:key`, loginUrl]} component={LoginPage} />

                                        <Route path={mapUrl}>
                                            <div className="notfound page-content">
                                                <h4>404</h4>
                                                <div>Page not found</div>
                                            </div>
                                        </Route>
                                    </Switch>
                                </>
                            );
                        }}
                    />
                </Switch>

                {!localStorage.acceptedCookies && <CookieBarComponent />}
            </Router>
        );
    }
}

export default App;
