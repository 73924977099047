import React from "react";
import {motion, AnimatePresence} from "framer-motion";

import "./Sidebar.scss";
import ImgLogo from "./../../img/nira-logo-white-pms.svg";
import ImgClose from "./../../img/icon-close.png";

interface SidebarProps {
    isOpen: boolean;
    closeSidebar: () => void;
}

export default class Sidebar extends React.Component<SidebarProps> {
    
    componentDidMount() {}

    render() {
        return (
            <AnimatePresence initial={false}>
                {this.props.isOpen && (
                    <motion.div
                        className="sidebarContainer"
                        initial={{x: 320, opacity: 0}}
                        animate={{x: 0.01, opacity: 1}}
                        exit={{x: 320, opacity: 0}}
                        transition={{
                            x: {type: "spring", bounce: 0.25},
                        }}
                    >
                        <span className="closeIcon" onClick={this.props.closeSidebar}>
                            <img width="18" height="18" src={ImgClose} alt=""/>
                        </span>
                        {this.props.children}
                        <div className="logoWrapper">
                            <img src={ImgLogo} alt=""/>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }
}