import React from "react";
import "./SidebarItem.scss";
import {ROUGHNESS_COLOR_MAP} from "../../components/map/MapConstants";

interface Props {
    header: string;
    amount: string;
    text?: string;
    unit?: string;
    statusColor?: string;
    colorBar?: any;
}

const renderColorBar = (props: Props) => {
    const gradient = "linear-gradient(90deg," + ROUGHNESS_COLOR_MAP + ")";
    const amount = parseFloat(props.amount);
    let positionLeft = 0;

    if (amount < 3) {
        positionLeft = ((amount - 0.25) / (3 - 0.25) / 2) * 100;
    } else {
        positionLeft = ((amount - 3) / (12 - 3) / 2 + 0.5) * 100;
    }

    return (
        <div className="colorBarWrapper">
            <div className="textWrapper">
                <p className="text">0</p>
                <p className="text">3</p>
                <p className="text">12</p>
            </div>
            <div className="colorBar" style={{backgroundImage: gradient}}>
                <span className="marker" style={{left: positionLeft + "%", transform: amount === 0 ? "" : "translateX(-100%)"}}></span>
            </div>
            <div className="textWrapper">
                <p className="text">Smooth</p>
                <p className="text">Rough</p>
            </div>
        </div>
    );
};

const SidebarItem = (props: Props) => {
    return (
        <div className="sidebarItem">
            <div className="header">
                {props.header}
                {props.statusColor && <span className="statusBanner" style={{backgroundColor: props.statusColor}}></span>}
            </div>
            <div className="amount">
                {props.amount}
                {props.unit && " " + props.unit}
            </div>
            {props.text && <p className="text">{props.text}</p>}
            {props.colorBar && renderColorBar(props)}
        </div>
    );
};

export default SidebarItem;
