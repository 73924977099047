import './ToggleButton.scss';

interface Props {
    text: string;
    onClick: () => void;
    selected: boolean;
}

const ToggleButton = (props: Props) => {
    const selected = props.selected ? "selected" : ""
    
    return (
        <div className="toggleButtonContainer" tabIndex={0} onClick={props.onClick}>
            <span className={"toggleButtonTitle " + selected}>{props.text}</span>
            <div className={"toggleButton " + selected}>
                <div className={"dot " + selected} />
            </div>
        </div>
    )
}

export default ToggleButton
