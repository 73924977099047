// import {DevelopmentAPI} from "./../../../map/src/utils/Configuration";
import {DevelopmentAPI} from "./../../utils/Configuration";
import chroma from "chroma-js";
export interface NiraVectorLayer {
    name: string;
    id: string;
    url: String;
    timestamp?: String;
    colorScale?: any;
}

export const ROUGHNESS_COLOR_MAP = ["#30133B", "#466CE4", "#29BBEC", "#30F198", "#A4FD3D", "#EED03B", "#FB8122", "#D23104", "#790402"];

export const VECTOR_LAYERS: Record<string, NiraVectorLayer> = {
    friction: {
        id: "Friction",
        name: "Friction",
        url: DevelopmentAPI.BASE_URL + "tiles/vector/aggregated/maintenance-aggregation/{z}/{x}/{y}",
        colorScale: chroma.scale(["red", "yellow", "green"]).classes([0, 0.35, 0.5, 1]),
    },
    roughness: {
        id: "Roughness",
        name: "Roughness",
        url: DevelopmentAPI.BASE_URL + "tiles/vector/aggregated/roughness-aggregation/{z}/{x}/{y}",
        timestamp: "2020-12-16T14:00:00Z",
        colorScale: chroma.scale(ROUGHNESS_COLOR_MAP).domain([0, 3, 12]).mode("hsl"),
    },
};

export const VECTOR_MAP_KEY = "C95B3CFF-89E8-4825-B74D-0BF85CD643A0";

export const MAP_TILE_LAYERS: Record<string, any> = {
    v1: {name: "V1", key: "2rAAs363nYRRQillIEgK", url: "https://api.maptiler.com/maps/a832e6fe-9fd0-4864-8788-65db8fb08206/{z}/{x}/{y}.png"},
    v2: {name: "V2", key: "fMgnS558G8rrOOyv5zUm", url: "https://api.maptiler.com/maps/f2c7456f-8144-4983-8e8c-8413c8797b60/{z}/{x}/{y}.png"},
};

export interface InterableObject<T> {
    [index: string]: T;
}
