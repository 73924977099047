import React from "react";

import "./MapActions.scss";

interface MapActionsProps {}
interface MapActionsState {}

export default class MapActions extends React.Component<MapActionsProps, MapActionsState> {

    render() {
        return (
            <div className="mapActionsContainer">
                {React.Children.map(this.props.children, (child, i ) => {
                    return (
                        <div className="mapActionsBox">
                            {child}
                        </div>
                    )
                })}
            </div>
        );
    }
}
