export class Configuration {
    static readonly AWS_REGION = "eu-west-1";
    static readonly AWS_USER_POOL = "eu-west-1_D8vINWDrE";
    static readonly AWS_CLIENT_ID = "75t36ff9ag8o4clehn23j07rle";
}

export enum DomainType {
    testing = "testing",
    staging = "staging",
    production = "production",
}

export class DevelopmentAPI {
    static readonly TEST_DOMAINS = ["nira.intun.io", "intun.io", "test.niradynamics.se"];
    static readonly STAGING_DOMAINS = ["www.staging.niradynamics.se", "ndbe.se"]; //Don't know if used or not
    static readonly PROD_DOMAINS = ["www.niradynamics.se"];
    static readonly DOMAIN_TYPE = ["testing", "staging", "production"][
        [DevelopmentAPI.TEST_DOMAINS, DevelopmentAPI.STAGING_DOMAINS, DevelopmentAPI.PROD_DOMAINS].findIndex(item => item.includes(window.location.hostname))
    ] as DomainType;

    // KEEPING THIS BECAUSE WE ARE SWITCHING LIKE MADMEN AT THE MOMENT
    static readonly BASE_URL = "api/";

    static readonly isProduction = DevelopmentAPI.DOMAIN_TYPE === DomainType.production ? true : false;
}
