import React, {useState} from "react";
import {NiraVectorLayer} from "../MapConstants";
import "./LayerSwitcher.scss";
import RadioButton from "./RadioButton";

interface Props {
    layerChanged: (layer: string) => void;
    currentVectorLayerId: string;
    vectorLayers: Record<string, NiraVectorLayer>;
}

const LayerSwitcher = (props: Props) => {
    const layerKeys = Object.keys(props.vectorLayers);
    const [selectedId, setSelectedId] = useState(props.vectorLayers[layerKeys[0]].name);
    const layerChanged = (id: string) => {
        setSelectedId(id);
        props.layerChanged(id);
    };

    const layers = Object.keys(props.vectorLayers).map(key => {
        const layer = props.vectorLayers[key];
        return <RadioButton key={key} text={layer.name} onClick={id => layerChanged(id)} selected={layer.name === selectedId} id={layer.name} />;
    });

    return (
        <div className="layerSwitcherRoot">
            <p>Road Graphics</p>
            {layers}
        </div>
    );
};

export default LayerSwitcher;
