import * as React from "react";
import {RouteComponentProps, Link} from "react-router-dom";
import Select from "react-select";
import Loader from "../../components/misc/Loader";
import LoginManagerInstance from "../../utils/LoginManager";
import {getCountries, getRegion, signUpWri, signUpOem} from "../../utils/Api";
import {SelectOption, SignUpWriRequest, SignUpOemRequest} from "../../utils/Interfaces";

// Styles & Graphics
import "./login.scss";

interface LoginMatchParams {
    page: string;
}

export interface LoginProps extends RouteComponentProps<LoginMatchParams> {}
export interface LoginState {
    // showNavigation: boolean
    showLogInModal: boolean;
    email?: string;
    password?: string;
    newpassword?: string;
    verificationCode?: string;
    signupstep?: string;
    name?: string;
    oem?: boolean;
    acceptTerms?: boolean;
    oem_expanded?: boolean;
    company?: string;
    position?: string;
    country?: SelectOption;
    region?: SelectOption;
    loading?: boolean;
    error?: string;
    success?: string;
    data_counties?: SelectOption[];
    data_region?: SelectOption[];
}

class SignIn extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {
            showLogInModal: false,
            signupstep: "",
            oem: false,
            oem_expanded: false,
            acceptTerms: false,
            loading: false,
            success: undefined,
            error: undefined,
        };
        this.callSubmitNewPassword = this.callSubmitNewPassword.bind(this);
        this.callSetNewPassword = this.callSetNewPassword.bind(this);
    }
    componentDidMount() {
        if (this.props.match.params.page === "signup") {
            document.title = "Sign up | " + process.env.REACT_APP_SITE_TITLE;
        } else {
            document.title = "Log in | " + process.env.REACT_APP_SITE_TITLE;
        }
        this.setState({signupstep: ""});
    }

    verifyemail() {
        const email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email_reg.test(String(this.state.email).toLowerCase())) {
            return false;
        } else {
            return true;
        }
    }

    async handleSubmitLogin(e: React.FormEvent) {
        e.preventDefault();

        if (!this.state.email || !this.state.password) {
            this.setState({error: "Fill in email and password."});
        } else {
            this.setState({loading: true});
            const result = await LoginManagerInstance.signIn(this.state.email, this.state.password);
            if (result) {
                if (LoginManagerInstance.isErrorMessage(result)) {
                    this.setState({error: result.error.message, loading: false});
                } else {
                    if (LoginManagerInstance.shouldResetPassword(result.challenge?.challengeName)) {
                        this.setState({signupstep: "showResetPassword", loading: false, newpassword: ""});
                        return;
                    }
                    this.setState({loading: false, success: "Your in!", error: ""});
                }
            } else {
                this.setState({loading: false, error: "Invalid email and/or password."});
            }
        }
    }

    async getCountryData() {
        if (!this.state.data_counties) {
            const countries = await getCountries();
            const countriesOptions = countries.map(country => {
                return {value: country.id.toString(), label: country.value};
            });
            this.setState({
                data_counties: countriesOptions,
            });
        }
    }

    async getRegionData(country_id: number) {
        if (country_id) {
            const regions = await getRegion(country_id);
            const regionOptions = regions.map(region => {
                return {value: region.id.toString(), label: region.value};
            });
            this.setState({data_region: regionOptions});
        } else {
            this.setState({data_region: []});
        }
    }

    async handleSubmitSignUp(e: React.FormEvent) {
        e.preventDefault();
        if (!this.state.name || !this.state.email) {
            this.setState({error: "Fill in all fields."});
        } else if (!this.verifyemail()) {
            this.setState({error: "Your email is incorrect."});
        } else if (!this.state.acceptTerms) {
            this.setState({error: "You have to accept the terms and conditions"});
        } else {
            this.setState({error: "", signupstep: "details"});
        }
    }

    async handleSubmitSignUpDetails(e: React.FormEvent) {
        e.preventDefault();
        if (!this.state.company || !this.state.position) {
            this.setState({error: "Fill in your company and position."});
        } else {
            if (this.state.oem) {
                this.signUpOem();
                //Make sure to get data region before the user reaches the Region screen
            } else {
                this.getCountryData();
                this.setState({error: "", signupstep: "region"});
            }
        }
    }
    async handleSubmitSignUpRegion(e: React.FormEvent) {
        e.preventDefault();
        if (!this.state.country || !this.state.region) {
            this.setState({error: "Fill in your country and region."});
        } else {
            this.signUpWri();
        }
    }
    async handleSubmitForgotPassword(e: React.FormEvent) {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({success: "", error: "Fill in your email address."});
        } else if (!this.verifyemail()) {
            this.setState({success: "", error: "Email is incorrect."});
        } else {
            this.setState({loading: true, success: "", error: ""});

            const response = await LoginManagerInstance.sendForgotPasswordRequest(this.state.email);

            const _this = this;
            setTimeout(function () {
                if (response && response.statusCode === 200) {
                    _this.setState({
                        loading: false,
                        success: "Done! If that email address is in our database, we will send you an email to reset your password.",
                        error: "",
                    });
                } else if (response.message) {
                    _this.setState({loading: false, success: "", error: response.message});
                } else {
                    _this.setState({loading: false, success: "", error: "Something went. Please try again or let us know via info@niradynamics.se"});
                }
            }, 500);
        }
    }

    async signUpWri() {
        if (!this.state.name || !this.state.email || !this.state.company || !this.state.position || !this.state.region) {
            this.setState({loading: false, error: "Some fields are missing. Fill them in and continue."});
        } else {
            this.setState({loading: true, error: ""});

            let body: SignUpWriRequest = {
                name: this.state.name,
                email: this.state.email,
                company: this.state.company,
                position: this.state.position,
                country: Number(this.state.country.value),
                region: Number(this.state.region.value),
            };
            const response = await signUpWri(body);

            if (response && response.statusCode === 200) {
                const _this = this;
                setTimeout(function () {
                    _this.setState({loading: false, signupstep: "done", error: ""});
                    _this.resetSignUpData();
                }, 1000);
            } else if (response.statusText) {
                this.setState({loading: false, error: response.statusText});
            } else {
                this.setState({loading: false, error: "Something went wrong when signing up. Please try again or let us know via info@niradynamics.se"});
            }
        }
    }
    async signUpOem() {
        if (!this.state.name || !this.state.email || !this.state.company || !this.state.position || !this.state.oem) {
            this.setState({loading: false, error: "Some fields are missing. Fill them in and continue."});
        } else {
            this.setState({loading: true, error: ""});

            let body: SignUpOemRequest = {
                name: this.state.name,
                email: this.state.email,
                company: this.state.company,
                position: this.state.position,
            };

            const response = await signUpOem(body);

            if (response && response.statusCode === 200) {
                const _this = this;
                setTimeout(function () {
                    _this.setState({loading: false, signupstep: "done", error: ""});
                    _this.resetSignUpData();
                }, 1000);
            } else if (response.statusText) {
                this.setState({loading: false, error: response.statusText});
            } else {
                this.setState({loading: false, error: "Something went wrong when signing up. Please try again or let us know via info@niradynamics.se"});
            }
        }
    }

    resetSignUpData() {
        this.setState({
            name: undefined,
            email: undefined,
            company: undefined,
            position: undefined,
            country: undefined,
            region: undefined,
            data_region: undefined,
            oem: false,
            oem_expanded: false,
            acceptTerms: false,
        });
    }

    resend() {
        console.log("ToDo: Add resend functionallity...");
        this.setState({loading: true});
        const _this = this;
        setTimeout(function () {
            _this.setState({loading: false});
        }, 2000);
    }

    renderLoginComponent() {
        return (
            <form className="form_wrapper" onSubmit={e => this.handleSubmitLogin(e)} key="login">
                <h2> Log in </h2>

                <div className="form-message">
                    {this.state.error && <span className="error"> {this.state.error} </span>}
                    {this.state.success && <span className="success"> {this.state.success} </span>}
                </div>
                <input
                    autoFocus
                    type="text"
                    name="email"
                    autoComplete="username"
                    placeholder="Email"
                    defaultValue={this.state.email}
                    className={this.state.error && "error"}
                    onChange={e => this.setState({error: "", email: e.currentTarget.value})}
                />
                <input
                    type="password"
                    name="password"
                    autoComplete="password"
                    placeholder="Password"
                    defaultValue={this.state.password}
                    className={this.state.error && "error"}
                    onChange={e => this.setState({error: "", password: e.currentTarget.value})}
                />
                <Link
                    to={process.env.PUBLIC_URL + "/login/showResetPassword"}
                    className="link"
                    style={{display: "block", textAlign: "right"}}
                    onClick={() => this.setState({signupstep: "", error: ""})}
                >
                    Forgot password?
                </Link>

                <div className="buttons_wrapper">
                    <Link
                        to={process.env.PUBLIC_URL + "/login/signup"}
                        className="button signup_button inverted"
                        onClick={() => this.setState({signupstep: "", error: ""})}
                    >
                        Sign up
                    </Link>
                    {this.state.loading ? (
                        <button>
                            <Loader />
                        </button>
                    ) : (
                        <input type="submit" className="button" value="Logga in" />
                    )}
                </div>
            </form>
        );
    }

    async callSubmitNewPassword(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        if (!this.state.newpassword) {
            this.setState({success: "", error: "Fill the new password."});
        } else {
            this.setState({loading: true, success: "", error: ""});

            const response = await LoginManagerInstance.updatePasswordChallenge(this.state.newpassword);

            const _this = this;
            setTimeout(function () {
                if (response && response.statusCode === 200) {
                    _this.setState({loading: false, newpassword: "", signupstep: "done", error: ""});

                    // _this.setState({loading: false, newpassword: "", success: "Done! Password reset", error: ""});
                } else if (response.message) {
                    _this.setState({loading: false, newpassword: "", success: "", error: response.message});
                } else {
                    _this.setState({
                        loading: false,
                        newpassword: "",
                        success: "",
                        error: "Something went wrong. Please try again or let us know via info@niradynamics.se",
                    });
                }
            }, 500);
        }
    }

    async callSetNewPassword(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!this.state.newpassword) {
            this.setState({success: "", error: "Fill the new password."});
        } else if (!this.state.verificationCode) {
            this.setState({success: "", error: "Fill the verification code."});
        } else {
            this.setState({loading: true, success: "", error: ""});

            const response = await LoginManagerInstance.forgotPasswordSubmit(this.state.email, this.state.newpassword, this.state.verificationCode);

            const _this = this;
            setTimeout(function () {
                if (response && response.statusCode === 200) {
                    _this.setState({loading: false, newpassword: "", success: "New password set. Go back and try to log in.", error: ""});
                } else if (response.message) {
                    _this.setState({loading: false, newpassword: "", success: "", error: response.message});
                } else {
                    _this.setState({
                        loading: false,
                        newpassword: "",
                        success: "",
                        error: "Something went wrong. Please try again or let us know via info@niradynamics.se",
                    });
                }
            }, 500);
        }
    }

    renderResetPasswordComponent() {
        return (
            <form className="form_wrapper" onSubmit={this.callSubmitNewPassword} key="resetPassword">
                <h2> Reset password </h2>

                <div className="form-message">
                    {!this.state.error && <span>Please enter a new password.</span>}
                    {this.state.error && <span className="error"> {this.state.error} </span>}
                    {this.state.success && <span className="success"> {this.state.success} </span>}
                </div>

                <input
                    autoFocus
                    type="password"
                    name="password"
                    autoComplete="password"
                    placeholder="Password"
                    className={this.state.error && "error"}
                    defaultValue={this.state.newpassword}
                    onChange={e => this.setState({error: "", newpassword: e.currentTarget.value})}
                />

                <div className="buttons_wrapper">
                    {this.state.loading ? (
                        <button>
                            <Loader />
                        </button>
                    ) : (
                        <input type="submit" className="button" />
                    )}
                </div>
            </form>
        );
    }
    renderEnterNewPasswordComponent() {
        return (
            <form className="form_wrapper" onSubmit={this.callSetNewPassword} key="resetPassword">
                <h2> Reset password </h2>

                <div className="form-message">
                    {!this.state.error && <span>Please enter a new password.</span>}
                    {this.state.error && <span className="error"> {this.state.error} </span>}
                    {this.state.success && <span className="success"> {this.state.success} </span>}
                </div>

                <input
                    type="text"
                    name="email"
                    autoComplete="username"
                    placeholder="Email"
                    defaultValue={this.state.email}
                    className={this.state.error && "error"}
                    onChange={e => this.setState({error: "", email: e.currentTarget.value})}
                />

                <input
                    autoFocus
                    type="password"
                    name="code"
                    autoComplete="password"
                    placeholder="Verification code"
                    className={this.state.error && "error"}
                    defaultValue={this.state.verificationCode}
                    onChange={e => this.setState({error: "", verificationCode: e.currentTarget.value})}
                />

                <input
                    autoFocus
                    type="password"
                    name="password"
                    autoComplete="password"
                    placeholder="Password"
                    className={this.state.error && "error"}
                    defaultValue={this.state.newpassword}
                    onChange={e => this.setState({error: "", newpassword: e.currentTarget.value})}
                />

                <div className="buttons_wrapper">
                    <Link
                        to={process.env.PUBLIC_URL + "/login"}
                        className="button signup_button inverted"
                        onClick={e => this.setState({success: "", error: "", signupstep: ""})}
                    >
                        Back{" "}
                    </Link>
                    {this.state.loading ? (
                        <button>
                            <Loader />
                        </button>
                    ) : (
                        <input type="submit" className="button" />
                    )}
                </div>
            </form>
        );
    }

    renderSignUpComponent() {
        return (
            <form className="form_wrapper" onSubmit={e => this.handleSubmitSignUp(e)} key="signup">
                <h2> Sign up </h2>

                <div className="form-message">
                    {this.state.error && <span className="error"> {this.state.error} </span>}
                    {this.state.success && <span className="success"> {this.state.success} </span>}
                </div>

                <input
                    autoFocus
                    type="text"
                    name="name"
                    autoComplete="name"
                    placeholder="Name"
                    defaultValue={this.state.name}
                    className={this.state.error ? " error" : ""}
                    onChange={e => this.setState({error: "", name: e.currentTarget.value})}
                />
                <input
                    type="text"
                    name="email"
                    autoComplete="username"
                    placeholder="Email"
                    defaultValue={this.state.email}
                    className={this.state.error && "error"}
                    onChange={e => this.setState({error: "", email: e.currentTarget.value})}
                />

                <div className="terms-checkbox">
                    <label className="clickable">
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            className="clickable"
                            onChange={e => this.setState({acceptTerms: e.currentTarget.checked, error: ""})}
                            defaultChecked={this.state.acceptTerms}
                        />{" "}
                        I accept the
                    </label>{" "}
                    <a href="/terms" className="link" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </a>
                </div>

                <div className="oem-checkbox">
                    <label className="clickable">
                        <input
                            type="checkbox"
                            name="oem"
                            className="clickable"
                            onChange={e => this.setState({oem: e.currentTarget.checked})}
                            defaultChecked={this.state.oem}
                        />{" "}
                        I represent an OEM organisation.{" "}
                    </label>

                    {this.state.oem_expanded ? (
                        <span className="link" onClick={e => this.setState({oem_expanded: false})}>
                            Read less
                        </span>
                    ) : (
                        <span className="link" onClick={e => this.setState({oem_expanded: true})}>
                            Read more
                        </span>
                    )}
                </div>

                <div className={"oem-info" + (this.state.oem_expanded ? " expanded" : "")}>
                    <p>
                        As a representant from an OEM you will get access to a demo version of the map service. The demo is representative of the service but
                        cannot be used as a tool.{" "}
                    </p>
                    <p>If you do not represent an OEM organisation, leave the checkmark unchecked.</p>
                </div>

                <div className="buttons_wrapper">
                    <Link to={process.env.PUBLIC_URL + "/login"} className="button inverted" title="Back to login">
                        {" "}
                        Cancel{" "}
                    </Link>
                    <input type="submit" className="button" value="Next" />
                </div>
            </form>
        );
    }

    renderSignUpDetailsComponent() {
        return (
            <form className="form_wrapper" onSubmit={e => this.handleSubmitSignUpDetails(e)} key="details">
                <h2>Details</h2>

                <p className="bold">Let us get to know you</p>
                <div className="form-message">
                    {this.state.error && <span className="error"> {this.state.error} </span>}
                    {this.state.success && <span className="success"> {this.state.success} </span>}
                </div>

                <input
                    autoFocus
                    type="text"
                    name="company"
                    autoComplete="company"
                    placeholder="Company/Organisation"
                    defaultValue={this.state.company}
                    className={this.state.error && "error"}
                    onChange={e => this.setState({error: "", company: e.currentTarget.value})}
                />
                <input
                    type="text"
                    name="position"
                    autoComplete="position"
                    placeholder="Position"
                    defaultValue={this.state.position}
                    className={this.state.error && "error"}
                    onChange={e => this.setState({error: "", position: e.currentTarget.value})}
                />

                <div className="buttons_wrapper">
                    <input type="button" className="button inverted" value="Back" onClick={e => this.setState({error: "", signupstep: ""})} />
                    {this.state.loading ? (
                        <button>
                            <Loader />
                        </button>
                    ) : (
                        <input type="submit" className="button" value="Next" />
                    )}
                </div>
            </form>
        );
    }

    handleCountryChange = selectedOption => {
        this.setState({error: "", country: selectedOption, region: undefined});
        this.getRegionData(selectedOption.value);
    };

    handleRegionChange = selectedOption => {
        this.setState({error: "", region: selectedOption});
    };

    renderSignUpRegionComponent() {
        const customStyles = {
            control: (theme, state) => ({
                ...theme,
                minHeight: "40px",
                height: "40px",
            }),
            menu: (provided, state) => ({
                ...provided,
                color: "#1d225a",
            }),

            valueContainer: (theme, state) => ({
                ...theme,
                height: "40px",
            }),

            indicatorsContainer: (theme, state) => ({
                ...theme,
                height: "40px",
            }),
        };

        return (
            <form className="form_wrapper" onSubmit={e => this.handleSubmitSignUpRegion(e)} key="region">
                <h2>Select region</h2>
                <p className="bold">Select the region you would like to monitor</p>
                <div className="form-message">
                    {this.state.error && <span className="error"> {this.state.error} </span>}
                    {this.state.success && <span className="success"> {this.state.success} </span>}
                </div>
                <Select
                    value={this.state.country}
                    onChange={option => this.handleCountryChange(option)}
                    options={this.state.data_counties}
                    styles={customStyles}
                />
                <div className="region-info">
                    Some countries are not covered by this service yet. <br />
                    Contact us for more information.
                </div>
                <Select value={this.state.region} onChange={option => this.handleRegionChange(option)} options={this.state.data_region} styles={customStyles} />

                <div className="buttons_wrapper">
                    <input type="button" className="button inverted" value="Back" onClick={e => this.setState({error: "", signupstep: "details"})} />
                    {this.state.loading ? (
                        <button>
                            <Loader />
                        </button>
                    ) : (
                        <input type="submit" className="button" value="Next" />
                    )}
                </div>
            </form>
        );
    }
    renderForgotPasswordRequest() {
        return (
            <form className="form_wrapper" onSubmit={e => this.handleSubmitForgotPassword(e)} key="requestpasswordreset">
                <h2> Reset password </h2>
                <div className="form-message">
                    {this.state.error && <span className="error"> {this.state.error} </span>}
                    {this.state.success && <span className="success"> {this.state.success} </span>}
                </div>

                <input
                    autoFocus
                    type="text"
                    name="email"
                    autoComplete="username"
                    placeholder="Email"
                    defaultValue={this.state.email}
                    className={this.state.error && "error"}
                    onChange={e => this.setState({success: "", error: "", email: e.currentTarget.value})}
                />

                <div className="buttons_wrapper">
                    <Link
                        to={process.env.PUBLIC_URL + "/login"}
                        className="button signup_button inverted"
                        onClick={e => this.setState({success: "", error: "", signupstep: ""})}
                    >
                        Back{" "}
                    </Link>

                    {this.state.loading ? (
                        <button>
                            <Loader />
                        </button>
                    ) : (
                        <input type="submit" className="button" value="Send" />
                    )}
                </div>
                <div className="buttons_wrapper">
                    <Link
                        to={process.env.PUBLIC_URL + "/login/enternewpassword"}
                        className="link"
                        style={{display: "block", textAlign: "right"}}
                        onClick={() => this.setState({signupstep: "", error: "", success: ""})}
                    >
                        I have a verification code{" "}
                    </Link>
                </div>
            </form>
        );
    }

    renderDoneComponent(title: string, contentText: string) {
        return (
            <div className="form_wrapper" key="done">
                <h2> Registration done </h2>
                <div className="bold"> A link to activate your account has been emailed to the address provided. </div>
                {/* <div className="done-email"> {this.state.email} </div> */}
                <div className="done-email"> </div>

                <div className="buttons_wrapper">
                    {/* {this.state.loading
                        ? <button className="inverted"><Loader /></button>
                        : <input type="button" className="button inverted" value="Resend" onClick={() => this.resend()}/>
                    } */}
                    <Link to={process.env.PUBLIC_URL + "/login"} type="button" className="button">
                        Go to login
                    </Link>
                </div>
            </div>
        );
    }

    renderComponent() {
        if (this.props.match.params.page === "signup") {
            switch (this.state.signupstep) {
                case "details":
                    return this.renderSignUpDetailsComponent();
                case "region":
                    return this.renderSignUpRegionComponent();
                case "done":
                    return this.renderDoneComponent("Registration done", "A link to activate your account has been emailed to the address provided. ");
                case "showResetPassword":
                    return this.renderResetPasswordComponent();
                default:
                    return this.renderSignUpComponent();
            }
        } else if (this.props.match.params.page === "enternewpassword") {
            switch (this.state.signupstep) {
                case "done":
                    return this.renderDoneComponent("New password set", "Go back and log in with the new password");
                default:
                    return this.renderEnterNewPasswordComponent();
            }
        } else if (this.props.match.params.page === "showResetPassword") {
            switch (this.state.signupstep) {
                case "done":
                    return this.renderDoneComponent("Registration done", "A link to activate your account has been emailed to the address provided. ");
                default:
                    return this.renderForgotPasswordRequest();
            }
        } else {
            if (this.state.signupstep === "showResetPassword") {
                return this.renderResetPasswordComponent();
            }

            return this.renderLoginComponent();
        }
    }

    render() {
        return (
            <div id="loginpage" className="page-content">
                {/*** TOP ***/}
                <section className="top_wrapper">
                    <section className="content-wrapper">
                        <div className="row">
                            <div className="column column33">
                                <h2 className="title"> Live Road Surface Map </h2>
                                <summary>
                                    The future of road surface monitoring. <br />
                                    Powered by data RSI is the benchmark.
                                </summary>
                            </div>

                            <div className="column column67">{this.renderComponent()}</div>
                        </div>
                    </section>
                </section>
            </div>
        );
    }
}

export default SignIn;
