import * as React from "react";
import MapComponent from "../../components/map/MapComponent";
import "./maps.scss";
import LoginManagerInstance, {UserData} from "../../utils/LoginManager";
import LayerSwitcher from "../../components/map/overlay/LayerSwitcher";
import {NiraVectorLayer, ROUGHNESS_COLOR_MAP, VECTOR_LAYERS} from "../../components/map/MapConstants";
import MapSidebar from "../../components/mapSidebar/MapSidebar";
import MapActions from "../../components/mapActions/MapActions";
import {FeatureLike} from "ol/Feature";
import ToggleButton from "../../components/map/overlay/ToggleButton";
import LocationIcon from "../../img/icons/location.svg";
import HomeRegionIcon from "../../img/icons/region.svg";
import UserSidebar from "../../components/userSidebar/UserSidebar";
import CompanyLogo from "../../img/nira-logo-white.svg";

export interface MapsProps {}
export interface MapsState {
    isLoggedIn: boolean;
    vectorLayerId: string;
    activeFeature: FeatureLike;
    isMapSidebarOpen: boolean;
    alertActive: boolean;
    userData: UserData;
    currentPosition?: {lon: number; lat: number};
    isGettingLocation: boolean;
    locationError: string;
    showZoomError: boolean;
}

class MapsPage extends React.Component<MapsProps, MapsState> {
    resetErrorMessageTimer: NodeJS.Timeout;
    mapRef: React.RefObject<any>;
    constructor(props: MapsProps) {
        super(props);
        this.state = {
            isLoggedIn: false,
            vectorLayerId: VECTOR_LAYERS.friction.id,
            activeFeature: null,
            isMapSidebarOpen: false,
            alertActive: false,
            userData: LoginManagerInstance.getCurrentUserData(),
            isGettingLocation: false,
            locationError: "",
            showZoomError: false,
        };
        this.mapRef = React.createRef();
        this.loginChanged = this.loginChanged.bind(this);
        this.orientationChanged = this.orientationChanged.bind(this);
        this.getCurrentPosition = this.getCurrentPosition.bind(this);
        this.goToHomeRegion = this.goToHomeRegion.bind(this);
    }

    componentDidMount() {
        document.title = "Map | " + process.env.REACT_APP_SITE_TITLE;
        LoginManagerInstance.addDataListener(this.loginChanged);
        window.addEventListener("orientationchange", this.orientationChanged);
    }

    componentWillUnmount() {
        LoginManagerInstance.removeDataListener(this.loginChanged);
    }

    orientationChanged(event) {
        this.forceUpdate();
    }

    loginChanged(userData: UserData) {
        this.setState({userData: userData});
    }

    currentLayerChanged(id: string) {
        this.setState({vectorLayerId: id});
        this.closeMapSidebar();
    }

    featureClicked(feature: FeatureLike) {
        this.setState({
            activeFeature: feature,
            isMapSidebarOpen: true,
        });
    }

    goToHomeRegion(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        this.mapRef.current.gotToHomeRegion();
    }

    getCurrentPosition() {
        clearTimeout(this.resetErrorMessageTimer);
        this.setState({isGettingLocation: true, locationError: "Getting location"});
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState({currentPosition: {lon: position.coords.longitude, lat: position.coords.latitude}});
                    this.setState({isGettingLocation: false, locationError: ""});
                },
                error => {
                    console.log("error when getting position", error);
                    this.setState({isGettingLocation: false, locationError: "Could not get location"});
                    this.resetErrorMessageTimer = setTimeout(() => {
                        this.setState({locationError: ""});
                    }, 5000);
                },
                {
                    timeout: 8000,
                }
            );
        } else {
            this.setState({isGettingLocation: false, locationError: "Could not get location"});
            this.resetErrorMessageTimer = setTimeout(() => {
                this.setState({locationError: ""});
            }, 5000);
            console.log("position not available in browser");
        }
    }

    closeMapSidebar() {
        this.setState({
            activeFeature: null,
            isMapSidebarOpen: false,
        });
    }

    toggleAlerts() {
        this.setState({alertActive: !this.state.alertActive});
    }

    renderGoToHomeRegionButton() {
        if (this.state.userData.mapHome) {
            return (
                <div className="buttonContainer">
                    <button disabled={this.state.isGettingLocation} className="mapButton" onClick={e => this.goToHomeRegion(e)}>
                        <img style={{opacity: this.state.isGettingLocation ? 0.5 : 1}} className="buttonIcon" alt="location" src={HomeRegionIcon} />
                    </button>
                </div>
            );
        }
        return <></>;
    }

    renderMapButtons() {
        return (
            <div className="mapButtons">
                {this.renderGoToHomeRegionButton()}
                <div className="buttonContainer">
                    <button disabled={this.state.isGettingLocation} className="mapButton" onClick={this.getCurrentPosition}>
                        <img style={{opacity: this.state.isGettingLocation ? 0.5 : 1}} className="buttonIcon" alt="location" src={LocationIcon} />
                    </button>
                    <p className="errorMessage">{this.state.locationError}</p>
                </div>
            </div>
        );
    }

    filterVectorLayers(): Record<string, NiraVectorLayer> {
        const layers = this.state.userData.layers;

        // TODO: WHen we know what the different layer names are we can improve this abomination.
        if (layers && layers.length === 1) {
            return {friction: VECTOR_LAYERS.friction};
        }

        return VECTOR_LAYERS;
    }

    renderMapActions() {
        const vectorLayers = this.filterVectorLayers();
        const layers = this.state.userData.layers;
        if (layers && layers.length === 1) {
            return <></>;
        }
        return (
            <MapActions>
                <LayerSwitcher
                    currentVectorLayerId={this.state.vectorLayerId}
                    vectorLayers={vectorLayers}
                    layerChanged={layer => this.currentLayerChanged(layer)}
                />
                <ToggleButton text="Alerts" onClick={() => this.toggleAlerts()} selected={this.state.alertActive} />
            </MapActions>
        );
    }

    renderRoughnessColorBar() {
        if (this.state.vectorLayerId === "Friction") {
            return <></>;
        }

        const gradient = "linear-gradient(180deg," + ROUGHNESS_COLOR_MAP + ")";

        return (
            <div className="roughnessColorBar">
                <p className="headerText">Smooth</p>
                <div className="colorBarWrapper">
                    <div className="colorBar" style={{backgroundImage: gradient}}></div>
                    <div className="textWrapper">
                        <p className="text">0</p>
                        <p className="text">3</p>
                        <p className="text">12</p>
                    </div>
                </div>
                <p className="headerText border">Rough</p>
                <p className="headerText">[IRI]</p>
            </div>
        );
    }

    render() {
        return (
            <div id="maps" className="map">
                <div className="mapContainer">
                    <MapComponent
                        ref={this.mapRef}
                        currentVectorLayerId={this.state.vectorLayerId}
                        vectorLayers={VECTOR_LAYERS}
                        featureClicked={feature => this.featureClicked(feature)}
                        coordinates={this.state.currentPosition}
                        setShowError={show => this.setState({showZoomError: show})}
                        mapHome={this.state.userData.mapHome}
                    />
                </div>
                <div className="overlay">
                    <img className="companyLogo" alt="company logo" src={CompanyLogo} />
                    <UserSidebar userData={this.state.userData} />
                    <MapSidebar
                        feature={this.state.activeFeature}
                        isOpen={this.state.isMapSidebarOpen}
                        closeSidebar={() => this.closeMapSidebar()}
                        currentVectorLayerId={this.state.vectorLayerId}
                    />
                    {this.renderMapButtons()}
                    {this.renderMapActions()}
                    {this.renderRoughnessColorBar()}
                    {this.state.showZoomError && <div className="zoomErrorWrapper">Zoom in to show road data</div>}
                </div>
            </div>
        );
    }
}

export default MapsPage;
