import React from "react";

import "./MapSidebar.scss";
import {FeatureLike} from "ol/Feature";
import SidebarItem from "./SidebarItem";
import Sidebar from "../sidebar/Sidebar";
import {VECTOR_LAYERS} from "../../components/map/MapConstants";

interface MapSidebarProps {
    feature: FeatureLike;
    isOpen: boolean;
    closeSidebar: () => void;
    currentVectorLayerId: string;
}
interface MapSidebarState {}

export default class MapSidebar extends React.Component<MapSidebarProps, MapSidebarState> {
    componentDidMount() {}

    getFrictionText(friction) {
        if (friction >= 0 && friction <= 0.35) {
            //red
            return "Critical slipperiness";
        } else if (friction > 0.35 && friction <= 0.5) {
            //yellow
            return "Semi-slippery conditions";
        } else {
            //green
            return "Good conditions";
        }
    }

    getDateTime(date) {
        return date.toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    getPrettyDate(date) {
        const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
        return date.toLocaleDateString(undefined, options);
    }

    renderFeatureContent(feature: FeatureLike): React.ReactNode {
        const properties = feature.getProperties();

        const isFriction = this.props.currentVectorLayerId === "Friction";
        const isRoughness = this.props.currentVectorLayerId === "Roughness";

        return (
            <div className="propertiesContainer">
                {isFriction && this.renderFrictionContent(properties)}
                {isRoughness && this.renderRoughnessContent(properties)}
                <SidebarItem
                    header="Updated"
                    // TODO change to fetched updated date
                    amount={this.getDateTime(new Date())}
                    text={this.getPrettyDate(new Date())}
                />
            </div>
        );
    }

    renderRoughnessContent(properties: Object) {
        const currentRoughness = parseFloat(properties["Current-Roughness"]).toFixed(2);
        return <SidebarItem header={"Roughness, [IRI]"} amount={currentRoughness} colorBar={VECTOR_LAYERS["roughness"].colorScale} />;
    }

    renderFrictionContent(properties: Object) {
        const frictionUnit = "μ";
        const frictionAverage = properties["Friction-Average"];
        const frictionMin = parseFloat(properties["Friction-Min"]).toFixed(2);
        const frictionMax = parseFloat(properties["Friction-Max"]).toFixed(2);
        const numberOfTGPs = properties["NumberOfTGPs"];
        let standardDeviation = parseFloat(properties["Friction-Average-StandardDeviation"]).toFixed(3);
        standardDeviation = standardDeviation === "0.000" ? "0.001" : standardDeviation;

        return (
            <>
                <SidebarItem
                    header={"Average Friction"}
                    amount={parseFloat(frictionAverage).toFixed(2)}
                    unit={frictionUnit}
                    text={this.getFrictionText(frictionAverage)}
                    statusColor={VECTOR_LAYERS["friction"].colorScale(Number(frictionAverage)).hex()}
                />
                <SidebarItem header={"Min Friction"} amount={frictionMin} unit={frictionUnit} />
                <SidebarItem header={"Max Friction"} amount={frictionMax} unit={frictionUnit} />
                <SidebarItem data-testid="id_standard_deviation" header={"Standard Deviation"} amount={standardDeviation} unit={frictionUnit} />
                <SidebarItem header={"Number of Measurements"} amount={numberOfTGPs} />
            </>
        );
    }

    render() {
        return (
            <Sidebar isOpen={this.props.isOpen} closeSidebar={this.props.closeSidebar}>
                {this.props.isOpen && this.renderFeatureContent(this.props.feature)}
            </Sidebar>
        );
    }
}
