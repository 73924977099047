import React from "react";

import "./UserSidebar.scss";
import Sidebar from "../sidebar/Sidebar";
import LoginManagerInstance, {UserData} from "../../utils/LoginManager";

interface UserSidebarProps {
    userData: UserData;
}
interface UserSidebarState {
    isSidebarOpen: boolean;
}

export default class UserSidebar extends React.Component<UserSidebarProps, UserSidebarState> {
    constructor(props: UserSidebarProps) {
        super(props);
        this.state = {
            isSidebarOpen: false,
        };
    }

    componentDidMount() {}

    userClicked() {
        this.setState({
            isSidebarOpen: true,
        });
    }

    closeUserSidebar() {
        this.setState({
            isSidebarOpen: false,
        });
    }

    getNameInitials(name?: string) {
        if (name === undefined) {
            return "";
        }
        const nameList = name.split(" ");
        const filteredList = nameList.filter(Boolean);
        let initials = filteredList.shift().charAt(0);
        initials += filteredList.length >= 1 ? filteredList.pop()?.charAt(0) : "";
        return initials.toUpperCase();
    }

    renderUserContent() {
        return (
            <div className="userContainer">
                <div className="userInfo">
                    <p className="usernameText">{this.props.userData?.displayName}</p>
                    <p className="emailText">{this.props.userData?.email}</p>
                </div>
                <div className="userActions">
                    <p className="userLink">
                        <a href="/terms" className="link" target="_blank" rel="noopener noreferrer">
                            Terms & Conditions
                        </a>
                    </p>
                    <p className="userLink">
                        <a href="/attributions.txt" className="link" target="_blank" rel="noopener noreferrer">
                            Attributions
                        </a>
                    </p>
                    <p className="userLink">
                        <a href="/contact" className="link" target="_blank">
                            Contact Nira
                        </a>{" "}
                        to manage account
                    </p>
                    <p className="logoutButton" onClick={() => LoginManagerInstance.signOut()}>
                        Log out
                    </p>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="userInitials" onClick={() => this.userClicked()}>
                    <h6>{this.getNameInitials(this.props.userData?.displayName)}</h6>
                </div>
                <Sidebar isOpen={this.state.isSidebarOpen} closeSidebar={() => this.closeUserSidebar()}>
                    {this.state.isSidebarOpen && this.renderUserContent()}
                </Sidebar>
            </>
        );
    }
}
